/*
 * File: Input.js
 * Project: autofi-front
 * Author: Simon Gourlet
 * -----
 * Copyright 2022 - Autofi
 */
import styled from "styled-components";
import Select from "react-select";
import { StyledComponentType } from "Type/ThemeTypes.type";

/**
* Styled component Input
* Type: input (provided via props)
*/

const mySelect = styled(Select).attrs((props: StyledComponentType) => (
{
    ...props,
    options: props.options,
    className: `medium_font_2 ${props.className}`,
    placeholder: props.placeholder,
    isDisabled: ((props.options !== undefined && props.options?.length > 1 ? false : true) || (props.disabled ?? false))
}
))`
font-size: 0.9rem;
letter-spacing: 0.5px;
border: 1px solid rgba(0, 0, 0, 0.23);
&:hover, &:focus {
    outline: none;
}
`;

export default mySelect;
