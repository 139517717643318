/*
 * File: UserParameters.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 28th September 2023 02:17:34
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 28th September 2023 03:28:56
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import SwitchsGroups from "Content/Switchs/SwitchsGroups";
import translator from "MultiLanguage/TranslationWrapper";
import { SwitchType } from "Type/SwitchGroups.type";
import { UserParametersType } from "Type/user.type";
import { useEffect, useState } from "react";

const UserParameters = (props: {user?: UserParametersType, callback: Function}) => {
    const [parameters, setParameters] = useState<SwitchType[]>([]);

    useEffect(() => {
        if (props.user) {
            let myParams: SwitchType[] = [];
            Object.entries(props.user).map(([key, val]) => {
                if (typeof(val) === 'boolean')
                    myParams.push({item: {id: "", Name: translator.decode(`users.parameters.${key}`), key}, checked: val});
            })
            setParameters(myParams);
        }
    }, []);

    return (
        <SwitchsGroups title={translator.decode("users.parameters.title")} data={parameters} callback={props.callback} />
    );
}

export default UserParameters;
