/*
 * File: OfferRow.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 12th September 2023 05:37:45
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 15th November 2023 03:26:26
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import RouterPathResolver from "Config/Router/RouterPathResolver";
import { useState, Fragment, useRef, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosSave } from "react-icons/io";
import Style from "Content/Offers/OfferRow.module.css";
import { OfferType, UpdatableOffer } from "Type/offer.type";
import OfferService from "Services/Offer/OfferService";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import {BsCheck} from "react-icons/bs";
import {ImCross} from "react-icons/im";
import { IconButton, TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import OfferHistoryList from "./OfferHistory/OfferHistoryList";

type OfferRowProps = {
    UpdatedAt: Date,
    id: string,
    modifiable: boolean,
    vehicle?: {
        id: string,
        vehicleLabel: string,
        regNb: string
    },
    sellName?: string,
    user?: {
        id: string,
        userLabel: string,
        companyName: string,
        commercialName: string,
        commercialID: string
    },
    amount: number,
    gifted: boolean,
    history?: OfferType[],
}

const OfferRow = (props: OfferRowProps) => {
    const [amount, setAmount] = useState(props.amount);
    const [gifted, setGifted] = useState(props.gifted);
    const [updatable, setUpdatable] = useState(false);
    const [updated, setUpdated] = useState<boolean | undefined>();
    const [spinner, setSpinner] = useState<boolean | undefined>(false);
    const updateVal = useRef<UpdatableOffer>({});
    const [open, setOpen] = useState(false);

    const manageUpdateValue = (valName: string, val: number | boolean) => {
        if (valName === "amount") {
            if ((val as number) !== props.amount) {
                updateVal.current.Value = (val as number);
                setUpdatable(true);
            } else
                updateVal.current.Value = undefined;

            setAmount(val as number);
        } else if (valName === "gifted") {
            if ((val as boolean) !== props.gifted) {
                updateVal.current.Gifted = (val as boolean);
                setUpdatable(true);
            } else
                updateVal.current.Gifted = undefined
            setGifted(val as boolean);
        }
        if (updateVal.current.Gifted === undefined && updateVal.current.Value === undefined)
            setUpdatable(false);
    };

    useEffect(() => {
        setAmount(props.amount);
        setGifted(props.gifted);
    }, [props.amount, props.gifted]);

    const manageUpdate = () => {
        setSpinner(true);
        OfferService.updateOffer(updateVal.current, props.id).then(
            (val) => {
                setUpdated(true);
                setSpinner(false);
            }
        ).catch((reason) => {
            console.error(`Unable to update offer: ${props.id}; reason: ${reason.message}`);
            setSpinner(false);
            setUpdated(false);
        });
    };

    return (<Fragment>
        <TableRow hover className={`${Style.row} ${props.gifted ? Style.giftedRow : ""}`}>
            { props.history && props.history.length > 0 ?
                <TableCell>
                    <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                    >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                : <TableCell />
            }
            <TableCell align="center" className={`${Style.cell}`}> {new Date(props.UpdatedAt).toLocaleString()} </TableCell>
            {
                props.vehicle ? <Fragment>
                    <TableCell align="center" className={`${Style.cell} text-capitalize`}>
                        <Link to={`${RouterPathResolver.pathCar_BY_ID.path.replace(":id", props.vehicle.id)}`}>
                            {`${props.vehicle.vehicleLabel}`}
                        </Link>
                    </TableCell>
                    <TableCell align="center" className={`${Style.cell} text-uppercase`}>
                        <Link to={`${RouterPathResolver.pathCar_BY_ID.path.replace(":id", props.vehicle.id)}`}>
                            {props.vehicle.regNb.toLocaleString()}
                        </Link>
                    </TableCell>
                </Fragment> : null
            }
            {
                props.sellName ?
                    <TableCell align="center" className={`${Style.cell}`}>
                        <p className={`${Style.wrapText}`}>
                            {props.sellName.toLocaleString()}
                        </p>
                    </TableCell>
                : null
            }
            {
                props.user ?
                    <Fragment>
                        <TableCell align="center" className={`${Style.cell} text-capitalize`}>
                            <Link to={`${RouterPathResolver.pathUser_BY_ID.path.replace(":id", props.user.id)}`}>
                                {`${props.user.userLabel}`}
                            </Link>
                        </TableCell>
                        <TableCell align="center" className={`${Style.cell} text-capitalize`}> {props.user.companyName.toLocaleString()} </TableCell>
                        <TableCell align="center" className={`${Style.cell} text-capitalize`}>
                            <Link to={`${RouterPathResolver.pathUser_BY_ID.path.replace(":id", props.user.commercialID)}`}>
                                {`${props.user.commercialName}`}
                            </Link>
                        </TableCell>
                    </Fragment>
                : null
            }
            <TableCell align="center" className={`${Style.cell} ${Style.input}`}>
                <Form.Control type="number" value={amount?.toString()} disabled={!props.modifiable} onChange={(e) => manageUpdateValue("amount", parseInt(e.target.value))} />
            </TableCell>
            <TableCell align="center" className={`${Style.cell}`}>
                <Form.Check key={`${props.id}-in`} disabled={!props.modifiable} checked={gifted} onChange={(e) => manageUpdateValue("gifted", e.target.checked)} type="switch" />
            </TableCell>
            <TableCell align="center" className={`${Style.cell}`} >
                <div className={`${Style.actionHolder}`}>
                    <Button disabled={!updatable || !props.modifiable} className={`${Style.action}`} variant={"link"} onClick={manageUpdate} >
                        <IoIosSave size={"1.5rem"} />
                    </Button>
                    <div className={`${Style.iconHolder}`}>
                        {
                            spinner ?
                                <LoadingScreen width="2rem" height="2rem" borderWidth="2px"/>
                            : null
                        }
                        {
                            !spinner && updated !== undefined ?
                                (
                                    updated === true ?
                                    <BsCheck color="limegreen" size={"2rem"} />
                                    : <ImCross color="crimson" />
                                )
                            : null
                        }
                    </div>
                </div>
            </TableCell>
        </TableRow>
        {
            props.history && props.history.length > 0 ?
            <OfferHistoryList history={props.history} open={open} modifiable={props.modifiable} />
            : null
        }
    </Fragment>)

}

export default OfferRow;
