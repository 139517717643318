/*
 * File: Picture.utils.ts
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 10:22:18
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 15th September 2023 03:48:59
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { ShowingPicture } from "Type/picture.type";

const getSmallestOrderPictureID = (data: ShowingPicture[]) => {
    if (data.length === 0)
        return "";
    var firstPicture: ShowingPicture = data[0];
    for (let i = 1; i < data.length; ++i) {
        if (firstPicture.order > data[i].order)
            firstPicture = data[i];
    }
    return firstPicture.id;
}

const getSmallestOrderPicture = (data: ShowingPicture[]) => {
    var firstPicture: ShowingPicture = data[0];
    for (let i = 1; i < data.length; ++i) {
        if (firstPicture.order > data[i].order)
            firstPicture = data[i];
    }
    return firstPicture;
}

export {getSmallestOrderPictureID, getSmallestOrderPicture}
