/*
 * File: HomeService.ts
 * Project: autobuy-back-office
 * File Created: Tuesday, 26th September 2023 03:45:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 27th September 2023 09:27:11
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Config from "Config/Config";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import CarrierService from "Services/Carrier/CarrierService";
import EmailService from "Services/Email/EmailService";
import GroupService from "Services/GroupService/GroupService";
import PackService from "Services/Pack/PackService";
import PartnerService from "Services/PartnerService/PartnerService";
import SellService from "Services/Sell/SellService";
import ServiceGeneral from "Services/ServiceGeneral";
import UserService from "Services/Users/UserService";
import { buildAxiosHeader } from "Utils/Request.utils";
import axios from "axios";

class HomeService {
    public async getSellsNPacksInfo(logout: Function) {
        const sellsInfo = await ServiceGeneral.manageAxiosResponse( () => { return SellService.getSellInfo() }, () => logout());
        // const packsInfo = await ServiceGeneral.manageAxiosResponse( () => { return PackService.getPackInfo()}, () => logout());
        sellsInfo.title = "sell";
        sellsInfo.path = RouterPathResolver.pathSell.path;
        // packsInfo.title = "pack";
        // packsInfo.path = RouterPathResolver.pathPack.path;
        // return [sellsInfo, packsInfo];
        return [sellsInfo];
    }

    public async getUsersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse( () => { return UserService.getUsersInfo()}, () => logout());
    }

    public async getGroupsInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse( () => { return GroupService.getGroupsInfo()}, () => logout());
    }

    public async getPartnersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse( () => { return PartnerService.getPartnersInfo()}, () => logout());
    }

    public async getCarriersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse( () => { return CarrierService.getCarriersInfo()}, () => logout());
    }

    private async getConnexionsInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.CONNEXIONINFO}`;
        return await ServiceGeneral.manageAxiosResponse( async () => { return await axios.get(url,  buildAxiosHeader())}, () => logout());
    }

    private async getSeenCarsInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.SEENCARINFO}`;
        return await ServiceGeneral.manageAxiosResponse(async () => { return await axios.get(url,  buildAxiosHeader())}, () => logout());
    }

    private async getOfferInfo(logout: Function) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.OFFERCARINFO}`;
        return await ServiceGeneral.manageAxiosResponse(async () => { return await axios.get(url,  buildAxiosHeader())}, () => logout());
    }

    private async getEmailInfo(logout: Function) {
        // const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.TIPIMAIL}`;

        const day = await ServiceGeneral.manageAxiosResponse(async () => { return await EmailService.getAll({from: "day"})}, () => logout());
        const week = await ServiceGeneral.manageAxiosResponse(async () => { return await EmailService.getAll({from: "week"})}, () => logout());
        const month = await ServiceGeneral.manageAxiosResponse(async () => { return await EmailService.getAll({from: "month"})}, () => logout());
        return {day, week, month};
    }

    public async getUsageInfo(logout: Function) {
        return Promise.all([
            this.getConnexionsInfo(logout),
            this.getSeenCarsInfo(logout),
            this.getOfferInfo(logout),
            this.getEmailInfo(logout)
        ]).then(result => {
            return {connexions: result[0].data, seenCars: result[1].data, offers: result[2].data, email: result[3]};
        });
        // const connexionInfo = (await this.getConnexionsInfo(logout)).data;
        // const seenCarsInfo = (await this.getSeenCarsInfo(logout)).data;
        // const offerInfo = (await this.getOfferInfo(logout)).data;
        // const emailInfo = (await this.getEmailInfo(logout));
        // return {connexions: connexionInfo, seenCars: seenCarsInfo, offers: offerInfo, email: emailInfo};
    }
}

export default new HomeService();
