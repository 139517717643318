/*
 * File: FindUser.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 10th October 2023 09:46:32
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 10th October 2023 11:20:34
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Autocomplete, TextField } from "@mui/material";
import translator from "MultiLanguage/TranslationWrapper";
import UserService from "Services/Users/UserService";
import { QueryParams } from "Type/queryParams";
import { useEffect, useMemo, useRef, useState } from "react"

type FindUserProps = {
    setUserID: Function,
    setSearchTerm?: Function,
    searchTerm?: string,
    queryParams?: QueryParams,
}

const FindUser = (props: FindUserProps) => {
    const [userValue, setUserValue] = useState<string | null>(null);
    const [usersOptions, setUsersOptions] = useState<{id: string, label: string}[]>([]);
    const [userInputValue, setUserInputValue] = useState<string>(props.searchTerm ? decodeURIComponent(props.searchTerm) : "");
    const firstLoad = useRef(true);

    const selectedUser = useMemo(() => {
        let optionFound =  usersOptions.filter((v) => v.label === userValue);
        if (optionFound.length > 0)
            return optionFound[0].label;
        return userValue;
    }, [userInputValue]);

    const selectableUserOptions = useMemo(() => {
        return usersOptions.map(v => v.label);
    }, [usersOptions]);

    useEffect(() => {
        if (firstLoad.current && props.setSearchTerm)
            props.setSearchTerm(userInputValue);
        const beforeSearch = setTimeout(async () => {
            searchUserOptions(userInputValue);
        }, 500);
        return () => clearTimeout(beforeSearch);
    }, [userInputValue]);

    const searchUserOptions = async (userSearchTerm: string) => {
        if (userSearchTerm.length >= 3 && usersOptions.findIndex((val) => val.label === userSearchTerm) === -1) {
            let searchOptions = await UserService.getUsersBySearch(userSearchTerm, props.queryParams);
            if (searchOptions)
                setUsersOptions(searchOptions.map(v => {return {label: `${v.FirstName} ${v.LastName} (${v.Company.Name})`, id: v.id}}));
        }
    };

    return (
        <Autocomplete
            freeSolo
            value={selectedUser}
            onChange={async (event: any, newValue: string | null) => {
                setUserValue(newValue);
                if (newValue)
                    props.setUserID(usersOptions.filter((v) => v.label === newValue)[0].id);
                else
                    props.setUserID(undefined);
            }}
            inputValue={userInputValue}
            onClickCapture={async () => {
                await searchUserOptions(userInputValue);
            }}
            onInputChange={async (event, newUserValue) => {
                // await searchUserOptions(newUserValue);
                setUserInputValue(newUserValue);
            }}
            id="combo-box-user"
            options={selectableUserOptions}
            sx={{ width: 300, backgroundColor:"white" }}
            renderInput={(params) => <TextField autoFocus {...params} label={`${translator.decode("dynamic.user")}`} />}
        />
    )
}

export default FindUser;
