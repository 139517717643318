/*
 * File: SellRow.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 18th January 2024 11:26:08
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 18th October 2024 12:12:57
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { TableCell, TableRow } from "@mui/material";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import translator from "MultiLanguage/TranslationWrapper";
import { SellElementType } from "Type/sell";
import Styles from "Content/Sell/SellRow.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SellService from "Services/Sell/SellService";
import Config from "Config/Config";

type SellPropsType = {
    index: number,
    sell: SellElementType
}

function SellRow(props: SellPropsType) {
    const navigate = useNavigate();
    const [offers, setOffers] = useState(0);
    const [providers, setProviders] = useState([...new Set(props.sell.InSell?.map(val => val.Car.Source).filter(x => x))]);
    const [seenCars, setSeenCars] = useState(0);
    const adminCountry = Config.COMMON.COUNTRY;

    useEffect(() => {
        SellService.getOfferAndView(props.sell.id).then((val) => {
            setOffers(val.offerCount ?? 0);
            setSeenCars(val.seenCarCount ?? 0);
        });
        if (adminCountry == "ES")
            SellService.getSellProviders(props.sell.id).then((val) => {
                setProviders(val);
            }).catch((reason) => {
                console.error(`Unable to pull sell providers: ${JSON.stringify(reason)}`)
                setProviders([]);
            })
    }, [props.sell]);

    return (
        <TableRow key={`sellRow-${props.index}`} onClick={() => {window.location.href = `${RouterPathResolver.pathSell_BY_ID.path.replace(":id", props.sell.id)}?page=1`; navigate(`${RouterPathResolver.pathSell_BY_ID.path.replace(":id", props.sell.id)}?page=1`, {state: {page: 1}})}}>
            <TableCell className={`${Styles.sellTd_7}`}> <img style={{objectFit:"cover", width:"100%", maxHeight:"6rem"}} src={props.sell.Picture?.URI ?? "/Default.jpg"} alt="sell Logo" /></TableCell>
            {
                adminCountry == "ES" ?
                <TableCell className={`${Styles.sellTd_7}`}>{providers.map((x, idx) => {return <p key={`p-${x}-${idx}`} className="m-0">{`${x}`} </p>})}</TableCell>
                : null
            }
            <TableCell className={`${Styles.sellTd_7}`}>{props.sell.Name?.toUpperCase()}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_7}`}>{new Date(props.sell.StartDate).toLocaleString()}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_7}`}>{new Date(props.sell.EndDate).toLocaleString()}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_3}`}>{props.sell._count.InSell}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_3}`}>{offers}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_3}`}>{seenCars}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_5}`}>{translator.decode(`sell.status.${props.sell.Status}`)}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_8}`}>{translator.decode(`sell.type.${props.sell.SellType.Name}`)}</TableCell>
            <TableCell align="center" className={`${Styles.sellTd_8}`}><button className="btn btn-outline-success" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`${RouterPathResolver.pathSell_BY_ID.path.replace(":id", props.sell.id)}?page=1&editable=true`, {state: {page: 1, editable: true}});
            }}>{translator.decode("dynamic.modify")}</button></TableCell>
        </TableRow>
    )
}

export default SellRow;
