/*
 * File: Actions.tsx
 * Project: autobuy-back-office
 * File Created: Sunday, 14th January 2024 05:24:29
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Sunday, 14th January 2024 05:53:18
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import LoadingScreen from "Content/LoadingScreen/LoadingScreen"
import { MouseEventHandler, useState } from "react"
import { Button } from "react-bootstrap"
import { BsCheck } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { IoIosSave } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import Style from "Content/Actions/SimpleActions.module.css";
import SimpleActions from "./SimpleActions";

function Actions(props: {variant: string, spinner?:boolean, updated?: boolean, disabled: boolean, manageUpdate?: MouseEventHandler<HTMLButtonElement>, manageDelete?: MouseEventHandler<HTMLButtonElement>}) {
    switch(props.variant){
        case "minimal":
            return (<SimpleActions spinner={props.spinner} updated={props.updated} disabled={props.disabled} manageDelete={props.manageDelete} manageUpdate={props.manageUpdate} />)
        default:
        return (<>
        </>)
    }
}

export default Actions;