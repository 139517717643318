/*
 * File: ProtectedRoute.tsx
 * Project: autobuy-back-office
 * File Created: Monday, 19th June 2023 04:25:05
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 26th June 2023 10:34:23
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { ReactElement } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthProvider';

function ProtectedRoute(props: {condition: string, redirectPath: string, children?: ReactElement<any, any>}) {
  const {isConnected, login, logout} = useAuth();

  const evalRes = eval(props.condition);

  if (!evalRes) {
      // user is not authenticated
      return <Navigate to={props.redirectPath} />;
    }
    return props.children ? props.children : <Outlet />;
};

export default ProtectedRoute;
