/*
 * File: CustomerPage.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 31st August 2023 03:22:45
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 11th October 2023 02:11:21
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { ChangeEvent, useState, MouseEvent, Fragment } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import ModalUser from "Content/Modals/ModalUser/ModalUser";
import TitlePage from "Content/HeadingPage/TitlePage/TitlePage";
import { LoadedMyUsersData } from "Type/user.type";
import translator from "MultiLanguage/TranslationWrapper";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import UserList from "./UserList";

function MyCompanyPage(_: any) {
    const loadData = useLoaderData() as LoadedMyUsersData;
    const location = useLocation();
    const navigate = useNavigate();
    const [showModalUser, setShowModalUser] = useState(false);

    const handleOrderBy = ( event: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement> ) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", (1).toString());
        searchParams.set("orderedby", (`${event.target.value},asc`));
        searchParams.set("per_page", "999");
        navigate("?" + searchParams.toString());
    };

    const handleToggleUserModal = (e: MouseEvent<HTMLElement>) => {
        setShowModalUser(false);
    };

    const handleCreateUser = (e: any) => {
        e.stopPropagation();
        setShowModalUser(true);
    };

    return (
        <Fragment>
            <div className="p-3">
                <TitlePage title={translator.decode("users.myCompany")} backLink={RouterPathResolver.pathHome.path} />
            </div>
            <div className="p-2 pb-1">
                <div className="d-flex justify-content-between">
                    <span>{translator.decode("dynamic.OrderBy")}</span>
                    <button onClick={ handleCreateUser } className="btn btn-primary">{translator.decode("dynamic.create")}</button>
                </div>
                <select name="orderedbyOption" id="orderedbyOption" onChange={(e)=> handleOrderBy(e)}>
                    <option value="LastName">{translator.decode("users.list.headers.LastName")}</option>
                    <option value="FirstName">{translator.decode("users.list.headers.Firstname")}</option>
                    <option value="Company">{translator.decode("users.list.headers.Company")}</option>
                    <option value="CommercialID">{translator.decode("users.list.headers.Commercial")}</option>
                    <option value="CreatedAt">{translator.decode("users.list.headers.CreatedAt")}</option>
                    <option value="Status">{translator.decode("users.list.headers.Status")}</option>
                </select>
            </div>
            <div className="p-3">
                <UserList Users={loadData.myUsers} nbPage={loadData.nb_page}  botPagination={true} actions={false} indicators={false} showStatus={false} showRole={true} />
            </div>
            <ModalUser title={translator.decode("dynamic.users")} show={showModalUser} onClose={handleToggleUserModal} closeModal={handleToggleUserModal} isClient={false} />
        </Fragment>
    );
}

export default MyCompanyPage;
