/*
 * File: AddOfferOnCar.tsx
 * Project: autobuy-back-office
 * File Created: Monday, 9th October 2023 10:29:33
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 22nd January 2024 04:58:24
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Button, FormControlLabel, FormGroup, TextField, Tooltip, Checkbox } from "@mui/material";
import translator from "MultiLanguage/TranslationWrapper";
import CarService from "Services/Car/CarService";
import UserService from "Services/Users/UserService";
import Styles from "Content/AddOfferOnCar/AddOfferOnCar.module.css";
import { Fragment, useEffect, useRef, useState } from "react";
import HrVertical from "Content/HrVertical/HrVertical";
import FindUser from "Content/FindUser/FindUser";
import { Form } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import { useUserContext } from "Hooks/useUserContext";

const AddOfferOnCar = (props: {carID: string, onOffer: Function}) => {
    const [userValue, setUserValue] = useState<string>("");
    const [carValues, setCarValues] = useState<{Sell: {Name: string, SellType: {Name: string}}, Car: {SellPrice: number, MinAutobuyPrice: number, MinSellerPrice: number}}>();
    const [carOffer, setCarOffer] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [pending, setPending] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean|undefined>();
    const [isUserSelected, setIsUserSelected] = useState<boolean>(true);
    const [giftOffer, setGiftOffer] = useState<boolean>(false);
    const self = useRef<any>({});
    const {Name, Role} = useUserContext();

    const textToDisplay = () => {
        if (carValues)
            if (["buynow", "closedauctionai"].includes(carValues.Sell.SellType.Name))
                return <p className="m-0">{translator.decode("dynamic.buy")}</p>;
            else if (["buynowvalidation", "closedauctionvalidation"].includes(carValues.Sell.SellType.Name))
                return <p className="m-0">{translator.decode("dynamic.reserve")}</p>;
            else
                return <p className="m-0">{translator.decode(`car.pricing.atPrice`)}</p>;
    };

    useEffect(() => {
        UserService.getMyProfile()
        .then(val => {
            self.current = val;
        }).catch((e) => {
            console.error(`Unable to pull my profile: ${JSON.stringify(e)}`);
        });
    }, []);

    useEffect(() => {
        if (userValue !== null) {
            CarService.getCarByIDForSearch(props.carID)
            .then((val) => {
                setCarValues(val);
            }).catch((e) => {
                console.error(`Unable to pull data for carID: ${props.carID}`);
            });
        }
        if (userValue)
            setIsUserSelected(true);
        else
            setIsUserSelected(false);
    }, [userValue]);

    useEffect(() => {
        if (userValue && carOffer)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [userValue, carOffer]);

    const handleAddingOffer = async (offerValue: number, directAssign?: boolean) => {
        setPending(true);
        let carOffered = await CarService.makeOfferForCar(props.carID, offerValue, self.current!.id, userValue, directAssign);
        setPending(false);
        if (carOffered == null || (Array.isArray(carOffered) && carOffered.length == 0))
            setSuccess(false);
        else
            setSuccess(true);
        return carOffered;
    };

    return (
        <Fragment>
            <FindUser setUserID={setUserValue} queryParams={{carID: props.carID, scope: "client"}}/>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={giftOffer} onClick={() => setGiftOffer(!giftOffer)}/>} label={translator.decode("dynamic.gift")} />
            </FormGroup>
            <div className={`${Styles.carOffers}`}>
                <Form className={`${Styles.carOfferInput}`} onSubmit={(e) => {{e.preventDefault(); return props.onOffer(async () => {return await handleAddingOffer(parseInt(carOffer), giftOffer)})}}}>
                    <TextField type="number" value={carOffer} onChange={(e) => setCarOffer(parseInt(e.target.value) < 1 ? "1" : e.target.value)} label={translator.decode(`car.pricing.makeOffer`)}/>
                    <Button type="submit" disabled={isDisabled} className={`${Styles.carOfferButton}`} variant="contained">{translator.decode(`car.pricing.atPrice`)}</Button>
                </Form>
                {
                    carValues ?
                        Object.entries(carValues.Car).map(([key, val], idx) => {
                            if (!val)
                                return;
                            return <div key={`price-${key}-${idx}`} className={`${Styles.carOfferInput}`}>
                                <HrVertical />
                                <p className="m-0">{translator.decode(`car.pricing.${key}`)}: {val}</p>
                                <Button disabled={!isUserSelected && pending == true} className={`${Styles.carOfferButtonAlt}`} variant="contained" onClick={() => props.onOffer(async () => {return await handleAddingOffer(val, giftOffer)})}>{textToDisplay()}</Button>
                            </div>
                        })
                    : null
                }
                {
                    Name?.toLowerCase() == "olivier d." ?
                        <div className="d-flex gap-3">
                            <HrVertical />
                            <Tooltip title={`Spécial Olivier: Achète le véhicule pour 1€. A NE PAS UTILISER A MOINS D'AVOIR LE VISAGE DU BUTTON!!`} >
                                <Button disabled={!isUserSelected && pending == true} className={`${Styles.carSpecialOfferButton}`} color="error" variant="contained" onClick={() => handleAddingOffer(1, true)}><img src={"/OlivierMagicPicture.png"} /></Button>
                            </Tooltip>
                        </div>
                    : null
                }
                <div className="d-flex gap-3">
                    {
                        pending ?
                            <LoadingScreen width="2rem" height="2rem" borderWidth="2px"/>
                        : null
                    }
                    {
                        !pending && success !== undefined ?
                        (
                            success === true ?
                            <BsCheck color="limegreen" size={"2rem"} />
                            : <ImCross color="crimson" />
                        )
                            : null
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default AddOfferOnCar;
