/*
 * File: CarCard.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 22nd August 2023 04:50:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 20th August 2024 06:01:09
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import translator from "MultiLanguage/TranslationWrapper";
import { CarInSell } from "Type/CarElement.type.hold";
import Styles from "./CarCard.module.css";
import { RxCrossCircled } from "react-icons/rx";
import { LuImport } from "react-icons/lu";
import CarOfferSummary from "Content/CarOffersSummary/CarOffersSummary";
import { filterUniqueOffers } from "Utils/offer.utils";
import { useEffect, useState } from "react";
import CarService from "Services/Car/CarService";
import { Offer } from "Type/offer.type";
import CarStats from "./CarStats/CarStats";
import useOnScreen from "Hooks/useOnScreen";

type CarCardProps = {
    car: CarInSell,
    handleDelete?: Function,
    handlePictureImport?: Function,
    handleNavigation: Function,
    sellID: string,
    addr?: string,
    state?: any,
}

function CarCard(props: CarCardProps) {
    const [oldOffers, setOldOffers] = useState<Offer[]>();
    const [ref, isVisible] = useOnScreen<HTMLDivElement>({
        threshold: 0.1, // Adjust this value as needed
    });

    useEffect(() => {
        if (isVisible && oldOffers == undefined)
            CarService.getCarOldOffersByID(props.car.id, props.sellID).then((val: {data: Offer[]}) => {
                if (val.data.length > 0)
                    setOldOffers(val.data);
                else
                    setOldOffers([]);
            }).catch((error) => {
                console.log(error.message);
                setOldOffers([]);
            })
    }, [isVisible]);

    return (
        <div ref={ref} className={`${Styles.carCard}`}>
            <div className={`${Styles.pictureBox}`} onClick={() => props.handleNavigation(props.car.id)}>
                {
                    props.car.Status === "SOLD" || props.car.Status === "RESERVED" ?
                    <div className={`${Styles.carStatusHolder}`}>
                        <h2 className={props.car.Status === "SOLD" ? Styles.sold : Styles.reserved} >{translator.decode(`car.status.${props.car.Status}`).toUpperCase()}</h2>
                    </div>
                    : null
                }
                <img className={`${Styles.picture}`} src={props.car.CarPicture && props.car.CarPicture.length > 0 ? props.car.CarPicture.sort((val1, val2) => val1.Index - val2.Index)[0].Pict.URI : "/Default.jpg"} alt={translator.decode("dynamic.carImage")} />
            </div>
            <div className={`${Styles.carBox}`}>
                <h3 className={`${Styles.carTitle} text-uppercase`} onClick={() => props.handleNavigation(props.car.id)}> {props.car.Brand} {props.car.Model} {props.car.Version} </h3>
                <div className={`${Styles.carInfoHolder}`}>
                    <div className={`${Styles.carInfo}`}>
                        <p className={`text-uppercase`}>{props.car.RegNb}</p>
                        <p className={`text-capitalize`}>{props.car.StoragePlace.City}</p>
                        <p>{new Date(props.car.CirculationDate).toLocaleDateString()}</p>
                        <p>{props.car.Mileage} km</p>
                    </div>
                    <div className={`${Styles.offersHolder}`}>
                        <CarOfferSummary Offers={filterUniqueOffers(props.car.Offers).slice(0, 3)} oldOffer={false} />
                        {
                            oldOffers && oldOffers.length > 0 ?
                                <CarOfferSummary Offers={filterUniqueOffers(oldOffers).slice(0, 3)} oldOffer={true} />
                                :null
                        }
                    </div>
                </div>
                <CarStats navigation={props.handleNavigation} Offers={props.car.Offers?.length} SeenCars={props.car.SeenCars?.length} CarID={props.car.id} OldOffers={oldOffers?.length ?? 0}/>
                <div className={`${Styles.icons}`}>
                    {
                    props.handlePictureImport ?
                        <LuImport className={`${Styles.import}`} height={"2rem"} width={"2rem"} onClick={(e) => {e.stopPropagation(); props.handlePictureImport!(props.car.id)}}/>
                        : null
                    }
                    {
                        props.handleDelete ?
                            <RxCrossCircled className={`${Styles.close}`} height={"2rem"} width={"2rem"} onClick={(e) => {e.stopPropagation(); props.handleDelete!(props.car.id, props.car.RegNb)}}/>
                        : null
                    }
                </div>
            </div>
        </div>
    );
}

export default CarCard;
