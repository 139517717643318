/*
 * File: offer.utils.ts
 * Project: autobuy-back-office
 * File Created: Tuesday, 16th January 2024 10:02:59
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 18th March 2024 10:30:05
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Offer, OfferType } from "Type/offer.type";

export function FormatOfferRes(offer: OfferType[], accessKey: string) {
    const myOffers = offer.sort((a, b) => new Date(b.UpdatedAt).getTime() - new Date(a.UpdatedAt).getTime());
    let tmp: {[x: string]: OfferType & {history?: OfferType[]}} = {};
    if (accessKey === "user")
        myOffers.forEach((val) => {
            if (tmp[`${val.User!.id}-${val.InSell!.id}`]) {
                tmp[`${val.User!.id}-${val.InSell!.id}`].history!.push(val);
            }
            else {
                tmp[`${val.User!.id}-${val.InSell!.id}`] = {...val};
                tmp[`${val.User!.id}-${val.InSell!.id}`].history = [];
            }
            // if (tmp[val.User!.id])
            //     tmp[val.User!.id].history!.push(val);
            // else {
            //     tmp[val.User!.id] = {...val};
            //     tmp[val.User!.id].history = [];
            // }
        });
    else if (accessKey === "car")
        myOffers.forEach((val) => {
            if (tmp[val.InSell!.id!])
                tmp[val.InSell!.id!].history!.push(val);
            else {
                tmp[val.InSell!.id!] = {...val};
                tmp[val.InSell!.id!].history = [];
            }
            // if (tmp[val.InSell!.Car!.id])
            //     tmp[val.InSell!.Car!.id].history!.push(val);
            // else {
            //     tmp[val.InSell!.Car!.id] = {...val};
            //     tmp[val.InSell!.Car!.id].history = [];
            // }
        });
    return tmp;
};

export function filterUniqueOffers(offers: Offer[]) {
    let tmp: {[x: string]: boolean} = {};
    let tmpOffers: Offer[] = [];
    offers.sort((v1, v2) => new Date(v1.UpdatedAt).getTime() - new Date(v2.UpdatedAt).getTime()).reverse().forEach(v => {
        if (!tmp[v.UserID]) {
            tmp[v.UserID] = true;
            tmpOffers.push(v);
        }
    });
    return tmpOffers.sort((v1, v2) => v1.Value - v2.Value).reverse();
}
