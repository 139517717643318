/*
 * File: CollectionService.ts
 * Project: autobuy-back-office
 * File Created: Friday, 4th August 2023 01:08:50
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 4th August 2023 12:01:21
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import axios  from "axios";
import Config from "Config/Config";
import { buildAxiosHeader } from "Utils/Request.utils";
import { RespVisualsDbType } from "Type/visuals.type";

export default class CollectionService {

    static async getCollectionsName() {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.COLLECTIONS}`;
        const collectionNames = await axios.get(url, buildAxiosHeader());
        return collectionNames.data;

    }

    static async getCollectionByName(name: string): Promise<RespVisualsDbType> {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.COLLECTIONS}/${name}`;
        const collection = await axios.get(url, buildAxiosHeader());
        return collection.data;
    }

}
